<template>
  <h1>Ça fait combien 1km autour de chez moi ?</h1>
  <Map />
</template>

<script>
// @ is an alias to /src
import Map from '@/components/Map.vue'

export default {
  name: 'Home',
  components: {
    Map
  }
}
</script>
<style scoped>
h1 {
  margin: .5em auto;
}
</style>