<template>
  <div id="map"></div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import turfCircle from '@turf/circle';

let map
let geocoder

export default {
  name: 'Map',
  mounted () {
    // TO MAKE THE MAP APPEAR YOU MUST
    // ADD YOUR ACCESS TOKEN FROM
    // https://account.mapbox.com
    mapboxgl.accessToken = 'pk.eyJ1IjoibGJpbmVhdSIsImEiOiJja2hhd3ZxMmUxZ2pwMnNsMmMzZzk5NnJjIn0.CPEtwtZvXE_CSJuC4w2yGA';
    map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
      center: [2.32282, 48.86940625], // starting position [lng, lat]
      bounds: [[-5.24227249992918, 41.3108229], [9.6601564929151, 51.148409399929]],
      zoom: 9, // starting zoom
      locale: {
        'FullscreenControl.Enter': 'Plein écran',
        'FullscreenControl.Exit': 'Quitter le plein écran',
        'GeolocateControl.FindMyLocation': 'Trouver ma localisation',
        'GeolocateControl.LocationNotAvailable': 'Localisation non disponible',
        'LogoControl.Title': 'Logo Mapbox',
        'NavigationControl.ResetBearing': 'Réinitialiser la boussole au nord',
        'NavigationControl.ZoomIn': 'Zoomer',
        'NavigationControl.ZoomOut': 'Dézoomer',
        'ScaleControl.Feet': 'ft',
        'ScaleControl.Meters': 'm',
        'ScaleControl.Kilometers': 'km',
        'ScaleControl.Miles': 'mi',
        'ScaleControl.NauticalMiles': 'nm'
      }
    });

    geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      countries: 'fr',
      placeholder: 'Rechercher'
    })
    map.addControl(
      geocoder,
      'top-left'
    );

    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl());

    map.on('load', () => {
      if(this.$route.query.address) {
        geocoder.query(this.$route.query.address)
      }
    });
    geocoder.on('result', ({ result }) => {
      this.cleanMap();
      this.draw1kmCircle(result.center[0], result.center[1])
      this.$router.replace({query: { address: result.place_name }})
    })
  },
  methods: {
    cleanMap () {
      if (map.getLayer('1km')) map.removeLayer('1km');
      if (map.getSource('1km')) map.removeSource('1km');
    },
    draw1kmCircle (lng, lat) {
      const center = [lng, lat];
      const radius = 1;
      const options = { steps: 64, units: 'kilometers' };
      const circle = turfCircle(center, radius, options);
      map.addSource('1km', {
        'type': 'geojson',
        'data': circle
      });
      map.addLayer({
        'id': '1km',
        'type': 'fill',
        'source': '1km',
        'layout': {},
        'paint': {
          'fill-color': '#088',
          'fill-opacity': 0.3
        }
      });

      const coordinates = circle.geometry.coordinates[0];

      // Pass the first coordinates in the LineString to `lngLatBounds` &
      // wrap each coordinate pair in `extend` to include them in the bounds
      // result. A variation of this technique could be applied to zooming
      // to the bounds of multiple Points or Polygon geomteries - it just
      // requires wrapping all the coordinates with the extend method.
      const bounds = coordinates.reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
      map.fitBounds(bounds, {
        padding: 20
      });
    }
  }
}
</script>

<style>
#map {
  flex: 1;
}
</style>
